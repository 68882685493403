<template>
  <div class="index" ref="indexPage">
    <Menu></Menu>
    <!-- 右侧主体开始 -->
    <div class="flex-1 content index-left">
      <div class="home-user-info bgfff">
        <div class="home-user flex flex-pack-justify flex-align-center">
          <div class="flex flex-align-center">
            <div class="portrait">
              <img src="../assets/images/touxiang.png" />
            </div>
            <div class="name">
              {{
                userInfo && userInfo.companyName
                  ? userInfo.companyName
                  : "待企业认证"
              }}（{{
                userInfo && userInfo.userName
                  ? userInfo.userName
                  : userInfo.phone
              }}）
            </div>
          </div>
          <div class="te_c address-info" v-show="false" @click="goAddress">
            <strong class="di_bl">0</strong>
            我的收货地址
          </div>
        </div>
        <div class="info flex flex-pack-justify flex-align-center te_c">
          <a href="javascript:;" class="p_r" @click="goMyOrder('')">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/icon1.png" />
            </div>
            <div class="txt">保函订单</div>
          </a>
          <a href="javascript:;" class="p_r" @click="goMyOrder('00')">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/icon2.png" />
            </div>
            <div class="txt">待提交</div>
          </a>
          <a href="javascript:;" class="p_r" @click="goMyOrder('01')">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/icon3.png" />
            </div>
            <div class="txt">审核中</div>
          </a>
          <a href="javascript:;" class="p_r" @click="goMyOrder('03')">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/icon4.png" />
            </div>
            <div class="txt">审核未通过</div>
          </a>
          <a href="javascript:;" class="p_r" @click="goMyOrder('04')">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/icon5.png" height="30" />
            </div>
            <div class="txt">待支付</div>
          </a>
          <a href="javascript:;" class="p_r" @click="goMyOrder('08')">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/icon6.png" />
            </div>
            <div class="txt">已生成保函</div>
          </a>
        </div>
      </div>
      <!-- <div class="home-ad bgfff p_r">
        <img src="../assets/images/ad1.png" class="di_bl" />
        <div class="text flex flex-pack-justify flex-align-center">
          <a href="javascript:;" class="tit" @click="goOrderTab"> 我的贷款 </a>
          <div class="con flex" v-if="credit && credit.score">
            <div class="item">
              <span class="di_bl">{{ credit.score }}</span>
              增信评分
            </div>
            <div class="item" v-if="credit && credit.score">
              <span class="di_bl">{{ credit.leve }}</span>
              增信等级
            </div>
            <div class="item" v-if="credit && credit.score">
              <span class="di_bl">{{ credit.money }}万</span>
              建议放贷额
            </div>
          </div>
        </div>
      </div> -->

      <div class="index-loan" v-if="loanList.length">
        <div class="index-loan-head">
          <h2>为您推荐以下中标贷产品</h2>
          <div class="index-loan-desc" v-if="credit && credit.score">
            您的企业增信评分是<b>{{ credit.score }}</b>，增信等级为<b>{{ credit.leve }}</b>
          </div>
        </div>
        <div class="index-loan-list">
          <div class="index-loan-item" v-for="item in loanList" :key="item.bankCode" @click="goLoanDetail(item.linkUrl)">
            <div class="index-loan-bank">
              <img src="../assets/images/home_loan_icon.png" alt="" />
              <h6>{{ item.bankName }}</h6>
            </div>
            <div class="index-loan-data">
              <dl>
                <dt>{{ item.rateSection }}</dt>
                <dd>年利率</dd>
              </dl>
              <dl>
                <dt>{{item.loanAmount}}万元</dt>
                <dd>您的预授信额度</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>

      <div class="home-order bgfff">
        <div class="tab flex p_r">
          <span
            class="p_r"
            v-for="(v, k) in orderTab"
            :key="k"
            :class="{ active: orderTabIndex === k }"
            @click="orderTabIndex = k"
            >{{ v }}</span
          >
          <a href="javascript:;" @click="goMyOrder">更多</a>
        </div>
        <div class="warp">
          <div class="con" v-show="orderTabIndex === 0">
            <div
              class="orderItem item flex flex-pack-justify"
              v-for="(item, index) in orderList"
              :key="index"
            >
              <div class="flex-1 text-content flex flex-align-center">
                <div class="icon flex flex-align-center flex-pack-center">
                  <img src="../assets/images/icon7.png" height="30" />
                </div>
                <div class="flex-1 text">
                  <div class="title">招标项目编号：{{ item.projectId }}</div>
                  <div class="des">项目名称：{{ item.projectName2 }}</div>
                  <div class="des">项目区域：{{ item.mergername }}</div>
                  <div class="des">
                    开标日期：<span>{{ item.tenderTime }}</span>
                  </div>
                </div>
              </div>
              <div class="flex flex-align-center state">
                <a
                  href="javascript:void(0);"
                  class="flex flex-align-center flex-pack-center examine"
                  @click="goMyOrder(item.status)"
                  >{{ OrderStatus(item.status) }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-info-tj bgfff">
        <div class="title flex flex-pack-justify p_r">
          <span class="p_r">标讯信息推荐</span>
          <a href="javascript:;" @click="goProject" class="p_r">更多</a>
        </div>
        <div class="biao-con">
          <div class="biao-tab flex p_r">
            <span
              class="p_r"
              v-for="(v, k) in infoTab"
              :key="k"
              :class="{ active: infoTabIndex === k }"
              @click="getInfoData(k)"
              >{{ v }}</span
            >
          </div>
          <div class="biao-box">
            <div>
              <div
                class="item"
                v-for="(item, index) in infoData"
                :key="index"
                @click="goBiaoMsg(item.bidId)"
              >
                <div class="tit">
                  <a href="" class="ellipsis di_bl">{{
                    item.bidProjectName
                  }}</a>
                </div>
                <div class="flex txt">
                  <span>{{
                    item.bidCategory === "3"
                      ? "招标公告"
                      : item.bidCategory === "4"
                      ? "招标变更"
                      : "中标通知"
                  }}</span>
                  <span class="flex-1">{{ item.bidProjectArea }}</span>
                  <span>{{ item.releaseTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-project bgfff">
        <div class="title flex flex-pack-justify p_r">
          <span class="p_r">投标项目推荐</span>
          <a href="javascript:;" class="p_r" @click="goTouList">更多</a>
        </div>
        <ul class="project-list">
          <li
            class="p_r flex flex-pack-justify"
            v-for="(item, index) in bidProRecList"
            :key="index"
          >
            <div class="flex-1 text-content flex flex-align-center">
              <div class="icon flex flex-align-center flex-pack-center">
                <img :src="item.itemIconUrl" class="di_bl" />
              </div>
              <div class="flex-1 text">
                <div class="tit" @click="goInsureDetail(item)">
                  投标项目名称:{{ item.tenderProjectName }}
                </div>
                <div class="">项目区域：{{ item.mergerName }}</div>
                <div class="">开标时间：{{ item.bidOpenTime }}</div>
                <div class="">
                  保函金额：<span>{{ item.businessAmount }}</span>
                </div>
              </div>
            </div>
            <div class="flex flex-align-center state">
              <a
                href="javascript:void(0);"
                class="flex flex-align-center flex-pack-center sc"
                :class="{ check: item.collectionStatus }"
                @click.stop="
                  addCollection(
                    item.collectionStatus,
                    item.tenderProjectCode,
                    index
                  )
                "
                >{{ item.collectionStatus ? "已收藏" : "加入收藏" }}</a
              >
              <a
                href="javascript:void(0);"
                class="flex flex-align-center flex-pack-center sq"
                @click.stop="goApply(item.tenderProjectCode)"
                >申请保函</a
              >
            </div>
          </li>
        </ul>
        <div class="index-loading" v-show="loadingFlag">
          <span v-if="hasNextPage"
            ><i class="el-icon-loading"></i>努力加载中</span
          >
          <span v-else>暂无更多数据</span>
        </div>
      </div>
    </div>
    <!-- 主体结束 -->
    <!-- 右侧开始 -->
    <div class="r-sidebar index-right">
      <el-carousel
        height="180px"
        arrow="never"
        :interval="5000"
        :indicator-position="swiperUrl.length > 1 ? '' : 'none'"
      >
        <el-carousel-item v-for="(item, index) in swiperUrl" :key="index">
          <img
            :src="item.imageUrl"
            :alt="item.remark ? item.remark : item.imageUrl"
            v-if="swiperUrl.length === 1"
            @click="swiperGo(item.linkUrl)"
          />
          <img
            :src="item.imageUrl"
            :alt="item.remark ? item.remark : item.imageUrl"
            @click="swiperGo(item.linkUrl)"
            v-else
          />
        </el-carousel-item>
      </el-carousel>
      <div class="ser">
        <h4 class="te_c">业务中心</h4>
        <div class="flex flex-warp bgfff">
          <a :href="url[0].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q1.png" />
            </div>
            <div class="txt">投标保函</div>
          </a>
          <a :href="url[1].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q2.png" />
            </div>
            <div class="txt">中标贷</div>
          </a>
          <a :href="url[2].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q3.png" />
            </div>
            <div class="txt">履约保函</div>
          </a>
          <a :href="url[3].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q4.png" />
            </div>
            <div class="txt">预付款保函</div>
          </a>
          <a :href="url[4].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q5.png" />
            </div>
            <div class="txt">支付保函</div>
          </a>
          <a :href="url[6].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q7.png" />
            </div>
            <div class="txt">质量保函</div>
          </a>
          <a :href="url[7].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q8.png" />
            </div>
            <div class="txt">农民工资险</div>
          </a>
          <a :href="url[5].href" class="te_c di_bl" target="_bank">
            <div class="icon flex flex-align-center flex-pack-center">
              <img src="../assets/images/q6.png" />
            </div>
            <div class="txt">施工意外险</div>
          </a>
        </div>
      </div>
    </div>
    <!-- 右侧结束 -->
  </div>
</template>

<script>
import imgUrl from "../assets/images/ad.png";
import Menu from "../components/Menu.vue";
import cityNameIcon from "../utils/js/cityIcon";
export default {
  name: "index",
  components: {
    Menu,
  },
  data() {
    return {
      activeName: "first",
      loanList: [],
      swiperUrl: [imgUrl],
      orderTab: ["我的订单"],
      orderList: [], //我的订单
      orderTabIndex: 0,
      infoTab: ["招标公告", "招标变更", "中标通知"],
      infoData: [],
      infoTabIndex: 0,
      bidProRecList: [], //投标项目推荐
      credit: {
        //我的信用
        score: "", //评分
        leve: "", //等级
        money: "", //放贷额
      },
      userInfo: {
        userName: "",
        companyName: "",
        phone: "",
      },
      pageNum: 1,
      pageFlag: true,
      hasNextPage: true,
      url: [],
      loadingFlag: false,
      total: 0,
      areaCode: "331100",
    };
  },
  created() {
    this.url = [
      {
        name: "投标保函",
        href: `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index03.do`,
      },
      {
        name: "中标贷",
        href: `${process.env.VUE_APP_BASE_URL}/bidTenderLoans/apply/bidLoans.do`,
      },
      {
        name: "履约保函",
        href: `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index04.do`,
      },
      {
        name: "预付款保函",
        href: `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index05.do`,
      },
      {
        name: "支付保函",
        href: `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index09.do`,
      },
      {
        name: "施工意外险",
        href: `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index10.do`,
      },
      {
        name: "质量保函",
        href: `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index06.do`,
      },
      {
        name: "农民工资险",
        href: `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index07.do`,
      },
    ];
    if (this.$cookies.get("ci")) {
      this.areaCode = this.$cookies.get("ci");
    }
  },
  mounted() {
    this.getData();
    this.getSwiper();
    this.getIndexLoan()
    document.getElementById("app").addEventListener("scroll", this.menu);
  },
  methods: {
    async getIndexLoan() {
      let res = await this.$getAPI.getIndexLoan();
      this.loanList = res
    },
    async getSwiper() {
      let res = await this.$getAPI.indexSwiper({ areaCode: this.areaCode });
      if (res) {
        this.swiperUrl = res;
      }
    },
    swiperGo(url) {
      if (url === "#") {
        return;
      } else {
        window.open(url);
      }
    },
    goLoanDetail(url){
      window.open(url)
    },
    goInsureDetail(item) {
      let href = `${process.env.VUE_APP_BASE_URL}/v2/html/detail.html?areaCode=${item.regionCode}&projectName=${item.tenderProjectName}&projectId=${item.tenderProjectCode}`;
      window.open(href);
    },
    OrderStatus(code) {
      let text = "";
      switch (code) {
        case "00":
          text = "申请中";
          break;
        case "01":
          text = "审核中";
          break;
        case "02":
          text = "审核通过";
          break;
        case "03":
          text = "审核未通过";
          break;
        case "04":
          text = "待支付";
          break;
        case "05":
          text = "已支付";
          break;
        case "06":
          text = "已放款";
          break;
        case "07":
          text = "已失效";
          break;
        case "08":
          text = "已生成保函";
          break;
        case "09":
          text = "已作废";
          break;
        case "10":
          text = "已完成";
          break;
        case "11":
          text = "待放款";
          break;
      }
      return text;
    },
    goAddress() {
      this.$router.push({ path: "/address" });
    },
    goCollection() {
      this.$router.push({ path: "/collection" });
    },
    goMyOrder(type) {
      this.$router.push({ path: "/myorder", query: { type } });
    },
    goOrderTab() {
      this.$router.push({ path: "/myorder", query: { tab: 1 } });
    },
    goProject() {
      window.open(`${process.env.VUE_APP_BID_URL}`);
    },
    goBiaoMsg(bidId) {
      window.open(`${process.env.VUE_APP_BID_URL}/Details/${bidId}`);
    },
    goApply(projectId) {
      window.open(
        `${process.env.VUE_APP_BASE_URL}/guaranteeCtrl/index03.do?projectId=${projectId}`
      );
    },
    goTouList() {
      window.open(`${process.env.VUE_APP_BASE_URL}/v2/html/list.html`);
    },
    async recommendaTion() {
      let res = await this.$getAPI.recommendaTion({
        areaCode: this.areaCode,
      });
      this.bidProRecList = this.setItemIcon(
        this.setCollectionStatus(res.dataInfo.list)
      );
    },
    async getData() {
      let res = await this.$getAPI.indexData({
        areaCode: this.areaCode,
      });
      this.orderList = res.guaranteeBoList;
      this.userInfo = res.userInfoBo;
      this.credit = res.companyScoreBo;
      this.infoData = res.announcementShowsList;
      this.recommendaTion();
    },
    menu() {
      let scroll = document.getElementById("app").scrollTop;
      let footerScroll =
        document.getElementsByClassName("footer")[0].offsetTop - 1000;
      this.loadingFlag = true;
      if (scroll >= footerScroll && this.pageFlag && this.hasNextPage) {
        this.pageFlag = false;
        this.getBid();
      }
    },
    async getBid() {
      this.$axios
        .get(
          `/api/uc/personal/pubu.do?offset=${this.bidProRecList.length}&count=20&areaCode=${this.areaCode}`
        )
        .then((result) => {
          let res = result.data.result;
          console.log(res);
          this.bidProRecList = this.bidProRecList.concat(
            this.setItemIcon(this.setCollectionStatus(res.dataInfo.list))
          );
          this.pageNum++;
          this.pageFlag = true;
          this.hasNextPage = res.dataInfo.hasNextPage;
          this.loadingFlag = false;
        })
        .catch(() => {
          this.loadingFlag = false;
          this.pageFlag = true;
        });
      // let res = await this.$getAPI.indexBidData({
      //   offset: this.bidProRecList.length,
      //   count: 20,
      // });
      // this.bidProRecList = this.bidProRecList.concat(
      //   this.setItemIcon(this.setCollectionStatus(res.dataInfo.list))
      // );
      // this.pageNum++;
      // this.pageFlag = true;
      // this.hasNextPage = res.dataInfo.hasNextPage;
    },
    async addCollection(flag, id, k) {
      if (flag) return;
      await this.$getAPI.addCollection({ projectId: id });
      this.$confirm("已成功加入收藏", "", {
        confirmButtonText: "去我的收藏看看",
        cancelButtonText: "继续浏览",
      }).then(() => {
        this.goCollection();
      });
      this.bidProRecList[k].collectionStatus = true;
    },
    setCollectionStatus(list) {
      let data = [];
      list.forEach((v, k) => {
        data[k] = v;
        data[k]["collectionStatus"] = false;
      });
      return data;
    },
    async getInfoData(index) {
      this.infoTabIndex = index;
      let type =
        index === 0
          ? "zhaoBiaoGongGao"
          : index === 1
          ? "zhaoBiaoBianGeng"
          : "zhongBiaoTongZhi";
      let res = await this.$getAPI.indexInfoData({ type });
      this.infoData = res;
    },
    setItemIcon(list) {
      let areaNameMapping = cityNameIcon;
      let arr = [];
      list.forEach((v) => {
        v["flagMathCode"] = areaNameMapping[v.regionCode.substr(0, 4) + "00"]
          ? areaNameMapping[v.regionCode.substr(0, 4) + "00"]
          : areaNameMapping[v.regionCode.substr(0, 2) + "0000"];
        v[
          "itemIconUrl"
        ] = `https://img.cyc-fund.com.cn/pc_recommend/images/list/${v.flagMathCode}.png`;
        arr.push(v);
      });
      return arr;
    },
  },
  beforeDestroy() {
    document.getElementById("app").removeEventListener("scroll", this.menu);
  },
};
</script>

<style lang="less" scoped>
.index {
  position: relative;
  .orderItem {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .project-list li .sc {
    &.check {
      background: url(../assets/images/followCheck.png) no-repeat 15px center;
      background-size: 18px;
    }
  }
  /deep/ .el-carousel__indicators--horizontal {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);

    .el-carousel__indicator--horizontal button {
      width: 9px;
      height: 9px;
      border: 2px solid #f6f7f9;
      background: transparent;
      border-radius: 50%;
      opacity: 1;
      padding: 11px 2;
    }

    .el-carousel__indicator--horizontal.is-active button {
      width: 20px;
      height: 8px;
      background: #ffffff;
      opacity: 1;
      border-radius: 10px;
    }
  }
  &-loading {
    text-align: center;
    padding-bottom: 10px;
  }
  &-loan {
    background: #ffffff;
    border: 1px solid #e0e5e4;
    margin-top: 20px;
    &-head {
      background: #f5f8fa;
      border-bottom: 1px solid #e0e5e4;
      padding: 16px;
      h2 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        line-height: 22px;
      }
    }
    &-desc {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      margin-top: 6px;
      b {
        color: #1f579f;
      }
    }
    &-list {
      margin: 14px;
      background: url(../assets/images/home-loan-bg.jpeg) no-repeat;
      background-size: 100% 100%;
    }
    &-item {
      padding: 14px 70px 10px 26px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 10px solid #fff;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
    }
    &-bank {
      flex: 1;
      display: flex;
      margin-left: 7px;
      img {
        width: 18px;
        height: 18px;
        margin-top: 3px;
      }
      h6 {
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 18px;
        color: #1f579f;
        line-height: 25px;
        margin-left: 7px;
      }
    }
    &-data {
      display: flex;
      margin-left: 66px;
      dl {
        text-align: center;
        dt {
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 18px;
          color: #1f579f;
          line-height: 25px;
        }
        dd {
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #5d94db;
          line-height: 20px;
        }
        &:first-child {
          margin-right: 75px;
        }
      }
    }
  }
}
.index-left {
  margin-right: 285px;
  margin-left: 185px;
}
.index-right {
  position: absolute;
  right: 0;
  top: -30px;
}
</style>